.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .codec-screen {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 5px;
    width: 100%;
    max-width: 1200px;
    margin: 10px auto;
    border: 2px solid var(--border-color);
    padding: 5px;
  }
  
  .codec-left, .codec-right, .codec-center {
    background-color: var(--box-bg-color);
    border: 1px solid var(--border-color);
  }
  
  .codec-left, .codec-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .codec-center {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .codec-info {
    text-align: left;
    margin-bottom: 10px;
  }
  
  .codec-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
  }
  
  .dapp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .dapp-container .box {
    width: calc(33.33% - 7px);
    margin-bottom: 10px;
    min-width: 250px;
    height: 280px;
  }
  
  .box {
    background-color: var(--box-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    font-size: 0.8em;
    box-sizing: border-box;
  }
  
  .box h2 {
    margin-top: 0;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  /* Add these media queries at the end of your App.css file */

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-column {
    width: 100%;
    margin-right: 0;
  }

  .box {
    width: 100%;
    margin-bottom: 20px;
  }

  .console {
    width: 100%;
    height: 200px; /* Adjust this value as needed */
  }

  .spiderman-container {
    display: none; /* Hide Spider-Man on mobile if desired */
  }

  /* Adjust header for mobile */
  .appHeader {
    flex-direction: column;
    align-items: center;
  }

  .wallet-adapter-button {
    margin-top: 10px;
  }

  .dapp-container {
    flex-direction: column;
  }

  .dapp-container .box,
  .hacking-simulator {
    width: 100%;
    margin-bottom: 20px;
    min-width: unset;
  }

  .box {
    height: auto;
    min-height: 280px;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .box {
    padding: 10px;
  }

  .glitch-text {
    font-size: 1.5em;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .codec-screen {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: none;
    margin: 10px 0;
  }

  .codec-left {
    order: 1;
    width: 100%;
    height: 25vh; /* Reduced from 50vh to 25vh */
  }

  .codec-center {
    order: 2;
    width: 100%;
    height: 33vh; /* One-third of the viewport height */
  }

  .codec-right {
    order: 3;
    width: 100%;
    height: auto; /* Let it take its natural height */
  }

  .console {
    height: 100%; /* Fill the entire codec-left container */
  }

  /* Adjust other components as needed */
  .spiderman-wrapper {
    display: none; /* Hide Spider-Man on mobile if desired */
  }

  .codec-info, .codec-controls {
    font-size: 0.9em; /* Slightly reduce font size for mobile */
  }
}