:root {
    --main-bg-color: #000000;
    --text-color: #00ff00;
    --border-color: #00ff00;
    --box-bg-color: rgba(0, 50, 0, 0.3);
  }
  
  body {
    background-color: var(--main-bg-color);
    color: var(--text-color);
    font-family: 'Courier New', monospace;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  /* Add these wallet adapter styles */
  .wallet-adapter-button {
    background-color: var(--box-bg-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-color) !important;
    font-family: 'Courier New', monospace !important;
    font-size: 12px !important;
    padding: 0.3rem 0.7rem !important;
    transition: all 0.3s ease !important;
  }
  
  .wallet-adapter-button:hover,
  .wallet-adapter-button:not([disabled]):hover {
    background-color: var(--text-color) !important;
    color: var(--main-bg-color) !important;
  }
  
  .wallet-adapter-modal-wrapper {
    background-color: var(--box-bg-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--border-color) !important;
  }
  
  .wallet-adapter-modal-button-close {
    background-color: var(--text-color) !important;
  }
  
  .wallet-adapter-modal-title,
  .wallet-adapter-modal-list-more {
    color: var(--text-color) !important;
  }