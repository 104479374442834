.token-list {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--text-color) var(--box-bg-color);
}

.token-list::-webkit-scrollbar {
    width: 8px;
}

.token-list::-webkit-scrollbar-track {
    background-color: var(--box-bg-color);
}

.token-list::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    border-radius: 4px;
}

.token-list::-webkit-scrollbar-thumb:hover {
    background-color: var(--border-color);
}

.token-item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    padding: 5px 0;
    align-items: center;
}

.token-item.header {
    font-weight: bold;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.token-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.token-image {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.total-value {
    margin-top: auto;
    padding-top: 5px;
}

.total-value hr {
    border: 0;
    height: 1px;
    background-color: var(--border-color);
    margin-bottom: 10px;
}

.total-value-content {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 5px 0;
}