.nft-input, .nft-button, .wallet-button {
    background-color: var(--box-bg-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    font-family: 'Courier New', monospace;
    transition: all 0.3s ease;
}

.nft-input {
    padding: 0.5rem;
    margin-right: 10px;
    width: 60%;
}

.nft-button, .wallet-button {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.nft-button:hover, .wallet-button:hover {
    background-color: var(--text-color);
    color: var(--main-bg-color);
}

.nft-scroll-container {
    height: 150px;
    overflow-y: auto;
    margin-bottom: 10px;
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: var(--text-color) var(--box-bg-color);
}

/* Webkit browsers like Chrome/Safari/Opera */
.nft-scroll-container::-webkit-scrollbar {
    width: 8px;
}

.nft-scroll-container::-webkit-scrollbar-track {
    background: var(--box-bg-color);
}

.nft-scroll-container::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    border-radius: 4px;
    border: 2px solid var(--box-bg-color);
}

.nft-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--border-color);
}

.nft-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nft-item.selected {
    border-color: var(--text-color);
    background-color: rgba(0, 255, 0, 0.1);
}

.nft-thumbnail {
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 10px;
}

.burn-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.selection-counter {
    font-size: 14px;
    color: var(--text-color);
}