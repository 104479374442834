.console {
    background-color: #000;
    color: #00ff00;
    font-family: 'Courier New', monospace;
    height: 200px;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9em;
    scrollbar-width: thin;
    scrollbar-color: var(--text-color) var(--box-bg-color);
  }
  
  .console-line {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  
  .console-dot {
    margin-right: 5px;
  }
  
  .console-message {
    word-break: break-all;
  }
  
  .console-dot.blink {
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  .console::-webkit-scrollbar {
    width: 8px;
  }
  
  .console::-webkit-scrollbar-track {
    background-color: var(--box-bg-color);
  }
  
  .console::-webkit-scrollbar-thumb {
    background-color: var(--text-color);
    border-radius: 4px;
  }
  
  .console::-webkit-scrollbar-thumb:hover {
    background-color: var(--border-color);
  }