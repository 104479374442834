.spiderman-wrapper {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }
  
  .spiderman-container {
    width: 100%;
    height: 100%;
  }
  
  .spiderman-gif {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }