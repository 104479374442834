.hacking-simulator {
    width: calc(33.33% - 7px);
    height: 280px;
    background-color: var(--box-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    font-family: 'Courier New', monospace;
    overflow-y: auto;
    font-size: 0.8em;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .hacking-simulator {
        width: 100%;
        height: auto;
        min-height: 280px;
    }
}

.hacking-simulator h1 {
    color: var(--text-color);
    font-size: 1.1em;
    margin: 0 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}

.hacking-simulator h2 {
    font-size: 1em;
    margin: 10px 0;
}

.hacking-simulator p {
    margin: 5px 0;
    word-wrap: break-word;
}

.hacking-simulator .input-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.hacking-simulator input[type="text"] {
    flex: 1;
    min-width: 0;
    background-color: var(--main-bg-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    padding: 5px;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
    margin-right: 5px;
    margin-bottom: 5px;
}

.hacking-simulator button {
    background-color: var(--box-bg-color);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    padding: 5px 10px;
    cursor: pointer;
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.hacking-simulator button:hover {
    background-color: var(--text-color);
    color: var(--main-bg-color);
}

.hacking-simulator .score {
    font-weight: bold;
    margin-top: 10px;
}

.hacking-simulator .unlock-button {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
}