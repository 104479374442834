.tamagotchi-container {
    background-color: #001100;
    padding: 5px;
    border-radius: 5px;
  }
  
  .tamagotchi-container h3 {
    color: #00ff00;
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .tamagotchi-character {
    color: #00ff00;
    text-align: center;
    font-family: monospace;
    margin: 5px 0;
  }
  
  .tamagotchi-stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .tamagotchi-controls {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  
  .tamagotchi-button {
    background-color: #003300;
    color: #00ff00;
    border: 1px solid #00ff00;
  }
  
  .tamagotchi-age {
    font-size: 1.2em;
    margin-bottom: 5px;
  }