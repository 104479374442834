.floating-footer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--box-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8em;
    transition: all 0.3s ease;
    z-index: 1000;
  }
  
  .floating-footer.minimized {
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  
  .floating-footer .minimize-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.8em;
  }
  
  .floating-footer .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .floating-footer a {
    color: var(--text-color);
    text-decoration: none;
    margin: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .floating-footer a:hover {
    text-decoration: underline;
  }
  
  .ascii-logo {
    font-size: 1.2em;
    margin-right: 5px;
  }
  
  .floating-footer a:first-child .ascii-logo {
    font-weight: bold;
    transform: scaleX(0.8);
  }
  
  .floating-footer a:last-child .ascii-logo {
    font-size: 1.4em;
  }