.appHeader {
    background-color: var(--box-bg-color);
    min-height: 60px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--border-color);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 60px;
}

.logo {
    padding-left: 15px; /* Add left padding to the logo container */
}

.logo h1.glitch-text {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #00ff00;
    text-shadow: 
      0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
    animation: glitch 500ms infinite;
    margin: 0;
    line-height: 40px;
}

.wallet-adapter-button {
    margin-right: 15px; /* Add right margin to the wallet button */
    height: 40px; /* Reduced height */
    padding: 0 10px; /* Adjusted padding */
    font-size: 14px; /* Adjusted font size */
}

.nav {
    display: none; /* Hide navigation by default */
}

@keyframes glitch {
    /* ... (keep your existing glitch animation) ... */
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .appHeader {
        flex-direction: column;
    }

    .header-content {
        width: 100%;
    }

    .logo h1.glitch-text {
        font-size: 1.5rem; /* Slightly smaller font on mobile */
    }

    .logo {
        padding-left: 10px;
    }

    .wallet-adapter-button {
        margin-right: 10px;
        height: 36px; /* Further reduced height for mobile */
        padding: 0 8px; /* Adjusted padding for mobile */
        font-size: 12px; /* Adjusted font size for mobile */
    }

    /* Adjust padding for mobile if needed */
    .logo {
        padding-left: 10px;
    }

    .wallet-adapter-button {
        margin-right: 10px;
    }
}